<template>
  <div class="pageContent">
    <!-- <noNavHeader title="地址管理" /> -->
    <div class="mallUser">
      <el-row>
        <!-- <el-col :span="3">
          <el-col :span="24">
            <div class="mallUserLeft">
              <div>
                <p class="mallUserLeftTitle">地址管理</p>
              </div> -->
              <!-- <router-link to="/mall/user/orderList">
                <p class="mallUserLeftItem">我的订单</p>
              </router-link> -->
              <!-- <router-link to="/mall/user/address">
                <p class="mallUserLeftItem">地址管理</p>
              </router-link>
            </div>
          </el-col>
        </el-col> -->
        <el-col :span="24">
          <router-view />
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import noNavHeader from "../../base/header/noNavHeader";
export default {
  components: {
    noNavHeader
  }
};
</script>
<style lang="scss" scoped>
.mallUser {
  padding: 15px 0;
  width: 80%;
  min-width: 1300px;
  margin: 0 auto;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.mallUser .mallUserLeft {
  padding-left: 15px;
  line-height: 40px;
}
.mallUser .mallUserLeft .mallUserLeftTitle {
  font-size: 16px;
}
.mallUser .mallUserLeft .mallUserLeftItem {
  font-size: 14px;
  color: #9999a2;
}
.router-link-active p.mallUserLeftItem {
  color: $commonThemeColor !important;
}
</style>
